import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import objectUtils from 'utils/objectUtils';
import { useWindowEvent } from 'modules/picasso-ui/state/useWindowEvent';

/**
 *
 * @param {} param0
 */
export default function AppAlertsSnackbar() {
    const [message, setMessage] = useState(null);

    useWindowEvent('myapp.message', (e) => {
        handleMessage(e.value);
    });

    const handleMessage = (message) => {
        if (objectUtils.isNullOrUndefined(message)) {
            return;
        }

        //fail if no text
        objectUtils.notNullNotUndefined(message.text);

        //fail if no severity
        objectUtils.notNullNotUndefined(message.severity);

        setMessage(message);
    };

    const handleClose = () => {
        setMessage(null);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <Snackbar 
            open={message !== undefined && message !== null} 
            autoHideDuration={message?.duration || 12000} 
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >   
            <div>
                {message && (
                    <Alert onClose={handleClose} severity={message.severity}>
                        {message.text}
                    </Alert>
                )}
            </div>
        </Snackbar>
    );
}
